<template>
  <v-date-picker is-range v-model="state.range" @dayclick="dayClick">
    <template v-slot="{ inputValue, inputEvents }">
      <div class="form-group__input">
        <span>
          <label class="t-lbl">{{ $t("message.start_date") }}</label>
          <div class="clearfix"></div>
          <input
            class="bg-white form-input border br1 px-2 py-1"
            :value="inputValue.start"
            v-on="inputEvents.start"
          />
        </span>
        <span>
          <label class="t-lbl">{{ $t("message.end_date") }}</label>
          <div class="clearfix"></div>
          <input
            class="bg-white form-input border br2 px-2 py-1"
            :value="inputValue.end"
            v-on="inputEvents.end"
          />
        </span>
        <span>
          <label>&nbsp;</label>
          <button @click="filterByDates" class="form-btn ml-10">
            {{ $t("message.update") }}
          </button>
        </span>
      </div>
    </template>
  </v-date-picker>
  <br />
  <table class="table">
    <thead>
      <th>{{ $t("message.dates") }}</th>
      <th>{{ $t("message.type") }}</th>
    </thead>
    <tbody>
      <tr v-for="(type, date) in state.journals" :key="date">
        <td width="200">{{ date }}</td>
        <td>
          <span
            class="mr-30 lowercase flex-center table-action"
            v-for="t in type.sort()"
            :key="t"
          >
            <router-link
              class="back-link"
              :to="`new?date=${date}&type=${t[0]}`"
            >
              {{ t[1] }} </router-link
            >&nbsp;
            <!-- <span>
              <router-link :to="`new?date=${date}&type=${t}`">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-eye"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#000000"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="12" r="2" />
                  <path
                    d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"
                  />
                </svg>
              </router-link>
            </span> -->
            <span>
              <router-link :to="`new?date=${date}&type=${t[0]}`">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-pencil"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#000000"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"
                  />
                  <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                </svg>
              </router-link>
            </span>
            <!-- Generate PDF -->
            <span @click="generatePdf(date, t[0])">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-file-text"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#000000"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                <path
                  d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
                />
                <line x1="9" y1="9" x2="10" y2="9" />
                <line x1="9" y1="13" x2="15" y2="13" />
                <line x1="9" y1="17" x2="15" y2="17" />
              </svg>
            </span>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { computed, onMounted, reactive } from "@vue/runtime-core";
import { useStore } from "vuex";
import router from "@/router";
import { useI18n } from "vue-i18n";
export default {
  name: "Journal",
  setup() {
    const prevDate = new Date(new Date().setDate(new Date().getDate() - 30));
    const { t } = useI18n();
    const state = reactive({
      range: {
        start: prevDate,
        end: new Date(),
      },
      journals: [],
    });
    const store = useStore();
    function filterByDates() {
      fetchJournals();
    }
    const client_uuid = computed(() => router.currentRoute.value.params.uuid);

    async function fetchJournals() {
      await store
        .dispatch("journal/fetchEntries", {
          type: t("message.buying"),
          client_uuid: client_uuid.value,
          from_date: state.range.start,
          to_date: state.range.end,
          isGroupedBy: true,
        })
        .then((res) => {
          let finalObj = {};
          Object.keys(res).forEach((key) => {
            finalObj[key] = [
              ...new Set(
                res[key].map((value) => {
                  if (value.type == "BUYING") {
                    value.translation = t("message.buying");
                  } else if (value.type == "SELLING") {
                    value.translation = t("message.selling");
                  } else if (value.type == "BANK") {
                    value.translation = t("message.bank");
                  } else if (value.type == "CASH") {
                    value.translation = t("message.cash");
                  } else if (value.type == "MISC") {
                    value.translation = t("message.misc");
                  } else if (value.type == "CARRY") {
                    value.translation = t("message.carry");
                  } else if (value.type == "PAY") {
                    value.translation = t("message.pay");
                  }
                  value.main = [value.type, value.translation];
                  return value.main;
                  // return value.type;
                })
              ),
            ];
          });

          //FOR MAKE UNIQUE ARRAY OF JOURNAL LIST
          let newSet = {};
          Object.keys(finalObj).forEach((key) => {
            newSet[key] = [];
            finalObj[key].filter((value) => {
              var duplicate = newSet[key].some((a) =>
                value.every((v, i) => v === a[i])
              );
              if (!duplicate) {
                newSet[key].push(value);
              }
            });
          });
          state.journals = newSet;
        });
    }

    // Update journals on date input/change
    function dayClick() {
      console.log("Day click....");
    }

    async function generatePdf(date, journalType) {
      await store
        .dispatch("journal/generatePdf", {
          client_id: state.client.id,
          journal_type: journalType.toLowerCase(),
          operation_type: "new-journal",
          print_date: date.toLocaleString(),
        })
        .then((res) => {
          if (res.status == "success") {
            let linkElement = document.createElement("a");
            linkElement.setAttribute("href", res.data);
            linkElement.setAttribute("target", "_blank");
            document.body.appendChild(linkElement);
            linkElement.click();
            document.body.removeChild(linkElement);
          }
        });
    }

    onMounted(async () => {
      store.commit("component/SET_LOADER", false);
      fetchJournals();

      if (!state.client)
        store
          .dispatch("client/fetchClientByUUID", { uuid: client_uuid.value })
          .then((res) => {
            state.client = res.data;
          });
    });
    return { state, filterByDates, dayClick, generatePdf };
  },
};
</script>
